var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "asyent-card",
        {
          staticClass: "fill-height",
          attrs: { title: _vm.title, expandable: "" },
          scopedSlots: _vm._u(
            [
              !_vm.readonly && !_vm.isDisabledFunc(_vm.name) && !_vm.noNew
                ? {
                    key: "toolbar-actions",
                    fn: function () {
                      return [
                        _c("asyent-button", {
                          attrs: {
                            "button-icon": "mdi-plus",
                            "button-tooltip": _vm.ae$lng("Add"),
                            icon: _vm.$vuetify.breakpoint.smAndDown,
                            text: !_vm.$vuetify.breakpoint.smAndDown,
                          },
                          on: { confirmed: _vm.openForNew },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm.hasRecords
            ? _c("v-simple-table", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _vm._l(_vm.itemHeadings, function (heading) {
                                  return _c(
                                    "th",
                                    { key: heading, staticClass: "text-left" },
                                    [_vm._v(" " + _vm._s(heading) + " ")]
                                  )
                                }),
                                !_vm.readonly && !_vm.isDisabledFunc(_vm.name)
                                  ? _c("th")
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm.hasRecords
                            ? _c(
                                "tbody",
                                _vm._l(_vm.value2, function (item, i) {
                                  return _c(
                                    "tr",
                                    { key: i },
                                    [
                                      _vm._l(
                                        _vm.itemValues,
                                        function (itemVal) {
                                          return _c(
                                            "td",
                                            {
                                              key: itemVal,
                                              staticClass: "text-left",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item[itemVal]) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      !_vm.readonly &&
                                      !_vm.isDisabledFunc(_vm.name)
                                        ? _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("asyent-button", {
                                                attrs: {
                                                  "button-icon": "mdi-pencil",
                                                  "button-tooltip":
                                                    _vm.ae$lng("Edit"),
                                                  icon: "",
                                                },
                                                on: {
                                                  confirmed: function ($event) {
                                                    return _vm.openForEdit(i)
                                                  },
                                                },
                                              }),
                                              !_vm.noDelete &&
                                              !_vm.noDeleteInTable
                                                ? _c("asyent-button", {
                                                    attrs: {
                                                      "button-icon":
                                                        "mdi-delete",
                                                      "button-tooltip":
                                                        _vm.ae$lng("Delete"),
                                                      icon: "",
                                                      "requires-confirmation":
                                                        "",
                                                    },
                                                    on: {
                                                      confirmed: function (
                                                        $event
                                                      ) {
                                                        return _vm.deleteSelected(
                                                          i
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3331405193
                ),
              })
            : _c("div", { staticClass: "text-caption font-italic mt-2" }, [
                _vm._v(_vm._s(_vm.ae$lng("No data"))),
              ]),
          _c("br"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
            scrollable: "",
            transition: "dialog-bottom-transition",
            width: "500px",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", "max-height": "56" } },
                [
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                  _c("v-spacer"),
                  !_vm.isNew && !_vm.noReset
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-reload",
                          "button-tooltip": _vm.ae$lng("Reset"),
                          icon: "",
                          "requires-confirmation": "",
                        },
                        on: { confirmed: _vm.resetItem },
                      })
                    : _vm._e(),
                  !_vm.isNew && !_vm.noDelete
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-delete",
                          "button-tooltip": _vm.ae$lng("Delete"),
                          icon: "",
                          "requires-confirmation": "",
                        },
                        on: { confirmed: _vm.deleteItem },
                      })
                    : _vm._e(),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-check",
                      "button-tooltip": _vm.ae$lng("Save"),
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.saveItem },
                  }),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-close",
                      "button-tooltip": _vm.ae$lng("Cancel"),
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.closeDialog },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "ma-0 pa-0",
                  style: {
                    "max-height": _vm.$vuetify.breakpoint.smAndUp
                      ? "500px"
                      : "",
                  },
                },
                [
                  _c(
                    "ValidationObserver",
                    { ref: _vm.internalFormReference, attrs: { disabled: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.formValid,
                            callback: function ($$v) {
                              _vm.formValid = $$v
                            },
                            expression: "formValid",
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm._l(
                                        _vm.itemHeadings,
                                        function (heading, h) {
                                          return [
                                            _c("div", { key: "div" + h }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "0.85rem",
                                                  },
                                                },
                                                [_vm._v(_vm._s(heading))]
                                              ),
                                            ]),
                                            _c("ValidationProvider", {
                                              key: "val" + h,
                                              attrs: {
                                                name: heading,
                                                rules:
                                                  _vm.formFieldsRules2[
                                                    _vm.itemValues[h]
                                                  ],
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("v-text-field", {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            "error-messages":
                                                              errors,
                                                            counter: "",
                                                            "single-line": "",
                                                            label: "",
                                                            dense:
                                                              _vm.appConfig
                                                                .forms.dense,
                                                            flat: _vm.appConfig
                                                              .forms.flat,
                                                            solo: _vm.appConfig
                                                              .forms.solo,
                                                            "solo-inverted":
                                                              _vm.appConfig
                                                                .forms
                                                                .soloInverted,
                                                            filled:
                                                              _vm.appConfig
                                                                .forms.filled,
                                                            outlined:
                                                              _vm.appConfig
                                                                .forms.outlined,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.itemData[
                                                                _vm.itemValues[
                                                                  h
                                                                ]
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.itemData,
                                                                _vm.itemValues[
                                                                  h
                                                                ],
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "itemData[itemValues[h]]",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }